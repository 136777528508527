.membership {
  width: 35rem;
  background: white;
  position: relative;
  padding: 2rem;
  border-radius: 38px;
  align-items: center;
}
.member-checkbox {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}
.member-header{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.member-list{
  max-height: 20rem;
  overflow: hidden;
  overflow-y: auto;
}
.unselect-button{
  text-align: end;
}
.subtitle {
  color: #545459;
}