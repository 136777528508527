.header {
  width: 100%;
  position: sticky;
  top: 0rem;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  align-items: center;
  background-color: white;
  z-index: 11;
}

.header--left .app-name {
  font-family: "EMPrintSemiBold";
}
.header--left .app-name a {
  color: #00aca8;
}
.header--left .app-name:hover {
  font-family: "EMPrintSemiBold";
}
.header--left .app-name:hover a {
  color: rgb(172, 171, 171);
}

.header--left .app-detail {
  color: #545459;
}
.header--right .em-c-btn--white {
  border: 1px solid #00aca8;
  color: #00aca8;
  font-size: 0.75rem;
  height: 2rem;
  z-index: 0;
}
