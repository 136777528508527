.team-mngt{
    padding: 2rem !important;
}
.team-mngt--btn-wrapper{
    padding: 1rem 1.5rem;
    background-color: #F8F8F8;
    border-radius: 9px;
    width: 30rem;
}
.team-mngt--btn-wrapper .em-c-btn{
    width: 14rem;
    margin: 0.5rem 0;
}
.team-mngt--loading{
    display: block;
    width: fit-content;
    height: fit-content;
}