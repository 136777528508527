.fortune-cookies {
  width: 40%;
  background: white;
  position: relative;
  padding: 2rem;
  border-radius: 11px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.fortune-cookies > .message {
  text-align: center;
  font-family: EMPrintSemiBold;
  font-size: 1.5rem;
}
.fortune-cookies > .sub-message {
  margin: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1rem;
}
.cookie-img {
  text-align: center;
}
.cookie-img img {
  width: 80%;
}
