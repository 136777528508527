.alert-message {
  width: 32.5rem;
  background: white;
  position: relative;
  padding: 2rem;
  border-radius: 11px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alert-message > .message {
  text-align: center;
  font-family: EMPrintSemiBold;
  font-size: 1.5rem;
}
.alert-message > .sub-message {
  margin-top: 0.5rem;
  font-family: EMPrintLight;
  font-size: 1rem;
}
.alert-message > .button-wrapper {
  margin-top: 1rem;
}
