.event-form {
  width: 35rem;
  background: white;
  position: relative;
  padding: 2rem;
  border-radius: 38px;
  align-items: center;
}
.input-label-custom {
  width: 5.5rem;
  font-family: EMPrintSemiBold;
}
.input-label-team-select {
  margin-left: 2rem;
  width: 3.5rem;
  font-family: EMPrintSemiBold;
}
.input-label {
  width: 4.5rem;
  font-family: EMPrintSemiBold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.2rem;
}
.date-picker {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}
.select-date {
  display: flex;
}
.select-date-warning {
  color: #d82424;
}
.select-date-warning.date-err {
  font-size: 0.85rem;
}
.select-type {
  align-items: center;
  display: flex;
  margin: 1rem 0;
}
.select-type select {
  margin-left: 0;
  padding-right: 2rem;
}
.optional-questions {
  margin-bottom: 1rem;
}
.optional-questions--question {
  margin-top: 0.25rem;
}
.optional-questions--sub-questions {
  display: flex;
  justify-content: space-between;
}
.optional-questions--sub-questions .sub-question {
  margin-top: 0.8rem;
}
.remark {
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
}
.remark textarea {
  border-radius: 11px;
  resize: none;
  margin-left: 0.8rem;
  min-height: 3rem;
  padding: 0.3rem;
}
.button-wrapper {
  display: flex;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: whitesmoke;
}

.overlay {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 10000 !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.team-icon-toggle {
  margin: 0px;
}
.team-icon-toggle .em-c-field__body {
  display: flex;
}
