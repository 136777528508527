.landing-page {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  max-width: none;
}
.landing-page--content {
  text-align: center;
  height: calc(100vh - 14rem);
  line-height: 3rem;
}
.landing-page--content h2 {
  margin: 4rem auto 2rem auto;
}
.landing-page--footer {
  margin: 1rem auto;
  color: #00aca8;
  text-align: center;
}
.landing-page--content .calendar-img{
  width: 70%;
  margin: auto;
}
.landing-page--footer .team-name {
  font-family: "EMPrintSemiBold";
}
.landing-page--footer .mini-heart {
  color: lightcoral;
}
