.user-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 35rem;
  margin-bottom: 0.35rem;
  padding: 0.8rem 1rem;
  border: 1px solid #cccccd;
  border-radius: 4px;
  cursor: pointer;
}
.user-card--selected {
  border-color: #00b468;
  background-color: rgba(0, 180, 104, 0.1);
}
.user-card__info {
  width: 90%;
  margin-left: 1rem;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
}
.user-card__info--name,
.user-card__info--email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user-card__info--name {
  font-family: EMPrintSemibold;
  margin-bottom: 0.3rem;
}
.user-card__info--email {
  font-family: EMPrintLight;
}

.user-card__avatar {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
.user-card__icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #00b468;
}
