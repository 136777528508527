.team-table {
  width: 100%;
  margin-bottom: 0.5rem;
}
.team-table_team-name {
  width: 100%;
  text-align: center;
  background: rgba(0, 172, 168, 0.65);
  color: white;
  padding: 0.5rem 0.5rem 0.5rem 4.6rem;
  border-radius: 11px 11px 0 0;
}
.team-table_record {
  padding-top: 0;
  display: flex;
  align-items: center;
}
.team-table_record .employee {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team-table_record .employee--subteam {
  font-size: 0.75rem;
  color: #6e6e6e;
  margin-right: 1rem;
}
.team-table_record .dates {
  border: 1px solid #4ba9a7;
  border-radius: 11px;
  padding: 0 1rem !important;
}
.team-table_record .dates .half-morning {
  background-position: left;
  left: 0;
}
.team-table_record .dates .half-evening{
  background-position: right;
  right: 0;
}
.team-table_record .dates .half-morning,
.team-table_record .dates .half-evening {
  background-color: #ffffffe0;
  position: absolute;
  width: 1rem;
}
@media only screen and (max-width: 110.313rem) {
  .team-table_record .dates .half-morning,
  .half-evening {
    width: 0.85rem;
  }
}
.empty-event {
  width: 100%;
  height: 100%;
}
.empty-event:hover {
  background-color: rgba(0, 172, 168, 0.3);
}
.disbled {
  cursor: default;
}

.empty-event {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.team-table_record:hover {
  background-color: rgba(0, 172, 168, 0.1);
}

.em-c-tooltip{
  width: 2rem;
  height: 2rem;
}

.training-icon{
  background-image: url('../../Assets/event-icons/training-icon.svg');
}
.business-trip-icon{
  background-image: url('../../Assets/event-icons/business-trip-icon.svg');
}
.public-holiday-icon{
  background-image: url('../../Assets/event-icons/public-holiday-icon.svg');
}
.sick-icon{
  background-image: url('../../Assets/event-icons/sick-icon.svg');
}
.vacation-icon{
  background-image: url('../../Assets/event-icons/vacation-icon.svg');
}
.wfh-icon{
  background-image: url('../../Assets/event-icons/wfh-icon.svg');
}
.on-call-icon{
  background-image: url('../../Assets/event-icons/on-call-icon.svg');
}
.meeting-icon{
  background-image: url('../../Assets/event-icons/meeting-icon.svg');
}
.personal-leave-icon{
  background-image: url('../../Assets/event-icons/personal-leave-icon.svg');
}
.team-building-icon{
  background-image: url('../../Assets/event-icons/team-building-icon.svg');
}
.workshop-icon{
  background-image: url('../../Assets/event-icons/workshop-icon.svg');
}
.dispatcher-icon{
  background-image: url('../../Assets/event-icons/dispatcher-icon.svg');
}
.different-team-icon {
  filter: hue-rotate(90deg)
}