.dropdown {
    position: absolute;
    right: 0;
    border: 1px solid #00aca8;
    border-radius: 4px;
}

.calendar--header svg {
    width: 1rem;
    margin-left: 0.5rem;
}

.em-c-btn__text, .em-c-input-group__text {
    color: #545459;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.em-c-btn:hover, .em-c-btn:focus, .em-c-dropdown-check.em-is-active .em-c-btn--is-dropdown, 
.em-c-dropdown.em-is-active .em-c-btn--is-dropdown, .em-c-dropdown-radio.em-is-active .em-c-btn--is-dropdown {
    border: none;
}

@media only screen and (max-width: 110.438rem) {
    button,
    .dropdown .em-c-btn {
        width: 11.5rem;
    }
}

.dropdown .em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
    display: block;
    width: 100%;
    padding: 0;
}

.em-c-dropdown-check__list {
    max-height: 13.5rem;
    overflow: hidden auto;
    padding: 0.5rem;
    border-bottom: 1px solid #00aca8;
}

.em-c-dropdown-check.em-is-active .em-c-dropdown-check__panel {
    border: 1px solid #00aca8;
    margin-top: 1px;
}

.em-c-checkbox--container input:checked~.em-c-checkbox--checkmark {
    background-color: #4ba9a7;
    border: 1px #4ba9a7 solid;
    display: flex;
    flex-direction: column;
}

.dropdown .em-c-btn__inner__text {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    color: #545459;
    width: 100%;
}
