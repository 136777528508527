.member-form__subteam--input {
  margin: 0 !important;
  height: 1.5rem;
  font-size: 0.9rem;
  width: 10rem;
}

.member-form__user-picker {
  position: absolute;
  z-index: 5;
  width: 52rem;
}
.member-form__user-picker .no-data {
  border: 1px solid #cccccd;
  font-family: EMPrintSemibold;
  padding: 0.7rem;
  width: 38rem;
  background: white;
  border-radius: 4px;
}
.member-form__user-picker--list {
  border: 1px solid #cccccd;
  width: 100%;
  max-height: 17rem;
  overflow: auto;
  overflow-x: hidden;
  z-index: 6;
  border-radius: 4px;
}
.member-form__user-picker .user-card {
  background-color: white;
  padding: 0.5rem 0;
  border: none;
  margin-bottom: -0.3rem;
  width: 100%;
}

.member-form__icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.member-form__icon.icon-disabled{
  color: darkgray;
  cursor: not-allowed;
}
.member-form__body--search-loading {
  position: absolute;
  display: flex;
  align-items: center;
  width: 52rem;
  justify-content: flex-end;
  padding: 1rem;
  z-index: 6;
}

.member-form .spinner .bounce1,
.member-form .spinner .bounce2,
.member-form .spinner .bounce3 {
  background-color: #00aca8;
}

.em-c-table__header {
  background: #021834;
  border-radius: 11px;
}
.em-c-table__cell {
  padding: 0.8rem;
  font-family: EMPrintRegular;
}
.em-c-table__cell .icon {
  text-align: center;
}

.em-c-table-object {
  margin: 2rem 0;
}
.em-c-table-object__body-inner {
  background: white;
}
.em-c-table-object__header {
  display: flex;
}

.em-c-field__body {
  margin: 0px;
}
.em-c-select.em-u-clickable {
  width: 7rem;
  background-color: white;
}
.em-c-search__body {
  width: 100%;
}
.em-c-search__input {
  height: 2.75rem;
  border-radius: 4px;
  margin: 0;
}
.member-form__body--loading {
  padding: 0.65rem;
  position: absolute;
  z-index: 10;
  margin: 0 28rem;
}
.em-c-toolbar__item {
  margin: 0;
}
