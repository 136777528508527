.app {
  display: flex;
}
.app-content {
  margin: 0 auto;
  padding: 0 1.5rem;
  right: 0rem;
}
hr {
  margin: 0.5rem;
}
.em-c-btn {
  color: #00aca8;
  border: 1px solid #00aca8;
  width: fit-content;
}
.em-c-btn:hover, .em-c-btn:focus{
  color: white;
  border: 1px solid white;
}
.em-c-btn.em-c-btn--white {
  border: 1px solid #00aca8;
  color: #00aca8;
  width: fit-content;
}
.em-c-btn.em-c-btn--white:hover {
  border: 1px solid #00aca8;
  background: #00aca8;
  color: white;
}
.em-c-btn.em-c-btn--primary {
  background: #00aca8;
  border: 1px solid #00aca8;
  width: fit-content;
  color: white;
}
.em-c-btn.em-c-btn--primary:hover {
  color: white;
  border: 1px solid #00aca8;
}
.em-c-btn.em-c-btn--primary:hover:before {
  background: #00aca8;
}
.em-c-btn.em-c-btn--primary:focus,
.em-c-btn.em-c-btn--primary:focus:before {
  color: #ffffff;
  background: #00aca8;
}
.content {
  margin-left: 20rem;
  width: 100%;
  min-width: fit-content;
  height: 100%;
  align-items: flex-start;
}

@media only screen and (max-width: 110.313rem) {
  .content {
    margin-left: 14.5em;
  }
}
