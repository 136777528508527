.team-form {
  padding: 2rem !important;
}
.sub-title {
  font-weight: 100;
  font-family: "EMprintRegular";
}
.team-form--content {
  padding: 1.5rem;
  background-color: #f8f8f8;
  border-radius: 9px;
  width: 55rem;
}
.team-form--content__membership {
  display: flex;
  justify-content: space-between;
  line-height: 2.5rem;
  align-items: center;
}
.member-err {
  margin-left: 1rem;
  font-family: EMPrintLight;
  color: #d82424;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
}
.team-form__icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.team-form__icon:hover {
  color: #d82424;
}
.warning {
  border-color: #d82424;
}
td.em-c-table__cell.em-js-cell.clickable {
  cursor: pointer;
}
