.event-detail{
    width: 32.5rem;
    background: white;
    position: relative;
    padding: 2rem;
    border-radius: 3px;
    align-items: center;
    line-height: 2rem;
}
.event-detail--content{
    margin: 1rem 0;
}