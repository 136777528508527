
.event {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
  background-size: cover;
  background-position: center;
}
.em-l-grid__item{
  flex-direction:row;
}