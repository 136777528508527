.team-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem 1rem;
  background: white;
  cursor: pointer;
}
.team-card__info {
  width: 90%;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
}
.team-card__info--name,
.team-card__info--members {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: EMPrintLight;
}
.team-card__info--name {
  color: inherit;
  font-family: EMPrintSemibold;
  margin-bottom: 0.3rem;
}
