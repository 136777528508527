.side-bar {
  position: fixed;
  overflow: hidden;
  background-color: #00aca8;
  width: 20rem;
  padding: 1.5rem 2rem;
  min-height: 100%;
  z-index: 10000;
}
@media only screen and (max-width: 110.313rem) {
  .side-bar {
    width: 14.5em;
  }
}
.side-bar--header {
  color: white;
  margin: 1rem 0 2rem 0;
  text-align: center;
}
@media only screen and (max-width: 110.313rem) {
  .side-bar--header {
    margin: 0;
  }
}
.side-bar--header img {
  border-radius: 50%;
  margin: 1rem 0;
  height: 12rem;
  width: 12rem;
}
@media only screen and (max-width: 96.875rem) {
  .side-bar--header img {
    height: 9rem;
    width: 9rem;
  }
}
@media only screen and (max-width: 80.625rem) {
  .side-bar--header img {
    height: 8rem;
    width: 8rem;
  }
}
.side-bar--header__name {
  font-size: 1.45rem;
  font-family: "EMPrintSemiBold";
  line-height: 1.2;
  margin-bottom: 0.4rem;
  opacity: 0.8;
}

@media only screen and (max-width: 110.313rem) {
  .side-bar--header__name {
    font-size: 1rem;
  }
}

.side-bar--header__email {
  font-size: 1.15rem;
  font-family: "EMPrintLight";
  line-height: 1.2;
  margin-bottom: 0.4rem;
  word-break: break-word;
}

.side-bar--icon-explanation {
  margin: 2rem 0;
  background-color: #87cecc;
  border-radius: 11px;
  padding: 0.5rem;
}

@media only screen and (max-width: 110.313rem) {
  .side-bar--icon-explanation {
    margin: 1.5rem 0;
  }
}
@media only screen and (max-height: 55rem) {
  .side-bar--icon-explanation {
    display: none;
  }
}
.side-bar--icon-explanation .icon-des {
  display: flex;
  align-items: center;
}
.side-bar--icon-explanation img {
  margin: 0.5rem;
  margin-right: 1rem;
  width: 2rem;
}
@media only screen and (max-width: 110.313rem) {
  .side-bar--icon-explanation img {
    width: 1.25rem;
  }
}
.side-bar--action-btns .icon-btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.icon-btn-wrapper svg {
  width: 1.5rem;
  height: 1.5rem;
  color: white;
  margin: 0.5rem;
}
button,
button.em-c-btn {
  background-color: transparent;
  width: 13.5rem;
  color: white;
  border: 1px solid white;
}
@media only screen and (max-width: 110.438rem) {
  button,
  button.em-c-btn {
    width: 7rem;
  }
}
.side-bar--app-name {
  font-family: "EMPrintSemiBold";
  font-size: 1.75rem;
}
.side-bar--user-info {
  margin: 0.5rem 0;
  text-align: center;
}
.side-bar--user-info .avatar {
  width: 12rem;
  border-radius: 50%;
}
.side-bar--user-info .name {
  margin-top: 1rem;
}
