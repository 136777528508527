.em-c-btn .em-c-btn__text {
    margin-bottom: 1px;
}

.em-c-btn--disabled {
    border-color: #e3e3e3 !important;
    color: #89898c !important;
    background-color: #cccccd !important;
}

.disabled:hover {
    cursor: pointer;
}

.em-c-toggle__label,
.em-c-toggle__label:last-child {
    border: 1px solid #00ACA8;
    color: #00ACA8;
}

.em-c-toggle__input:checked+.em-c-toggle__label,
.em-c-toggle__label.em-is-active {
    background: #00ACA8;
    color: #ffff;
}

.em-c-toggle__label.em-is-disabled {
    border: 1px solid #575757;
    color: #000000;
}

.em-c-toggle__input:checked+.em-c-toggle__label.em-is-disabled {
    background: #575757;
    color: #ffff;
}
