.calendar {
  margin-top: -1rem;
}

.calendar--header {
  position: sticky;
  margin-top: -1rem;
  top: 4rem;
  z-index: 10;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar--filter {
  position: sticky;
  justify-content: center;
}

.calendar--header svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #4ba9a7;
  cursor: pointer;
}

.calendar-wrapper {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.year {
  z-index: 10;
  margin-top: -0.5rem;
  color: #8d8d8d;
  font-size: 0.85rem;
}

.calendar--table {
  min-width: fit-content;
  padding: 1.45rem 1.5rem;
  background: #f8f8f8;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: -1rem 2.5rem 0 2.5rem;
}

@media only screen and (max-width: 110.313rem) {
  .calendar--table {
    margin: -1rem 0 0 0;
  }
}

.calendar--table-owner {
  min-width: fit-content;
  padding: 0px 1.5rem;
  background: #f8f8f8;
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 0 2.5rem;
  position: sticky;
  top: 10.32rem;
  margin-top: -1rem;
  z-index: 1;
}

@media only screen and (max-width: 110.313rem) {
  .calendar--table-owner {
    margin: 0;
  }
}

.table-header {
  color: #4ba9a7;
  display: flex;
  align-content: center;
  width: 100%;
  padding: 0 0.5rem;
}

.table-header .weekend {
  color: #8d8d8d;
}

.table-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.table-body .weekend {
  border: none;
  background-color: #cecece;
}

.employee {
  min-width: 15rem;
  width: 25%;
  margin: 5px;
}

.dates {
  display: flex;
  margin: 0.5rem 0;
  padding: 0 0.75rem;
}

.dates-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}

@media only screen and (max-width: 110.313rem) {
  .dates-input {
    width: 2rem;
    height: 2rem;
  }
}

.date-border {
  border-left-color: #cecece;
  border-left-style: dashed;
  border-left-width: 1px;
}

.dates .today {
  background-color: #4ba9a7;
  border-radius: 50%;
  color: white;
}

.react-datepicker {
  font-family: "EMPrintRegular";
  font-size: 1rem;
  border-radius: 11px;
  z-index: 20;
}

.react-datepicker-popper {
  z-index: 100;
  margin-top: 16rem;
  transform: translate3d(-50%, 10px, 0px) !important;
}

.react-datepicker__tab-loop {
  position: absolute;
}

.react-datepicker-wrapper input {
  cursor: pointer;
  caret-color: transparent;
  text-align: center;
  border: none;
  font-size: 2rem;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  width: 20rem;
}

.react-datepicker__month-wrapper {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-around;
}

.react-datepicker__input-container {
  cursor: pointer;
  caret-color: transparent;
  text-align: center;
  border: none;
  font-size: 2rem;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-radius: 9px 9px 0 0;
  padding: 0.5rem;
  background: #4ba9a7;
  color: whitesmoke;
}

.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected {
  width: auto;
  margin: 2px;
  padding: 2px 5px;
}

.content-loading {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.type-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-size: cover;
  background-position: center;
}

.type-icon-sidebar {
  width: 2rem;
  height: 2rem;
  margin: 0.35rem 1rem 0.35rem 0.5rem;
  background-size: cover;
  background-position: center;
}